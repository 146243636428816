import { joinURL } from 'ufo';
import { Buffer } from 'buffer';
// @ts-ignore
import { hmac, sha256 } from 'hash.js';

const config = useRuntimeConfig()

export const getImage = (src: string, watermark: boolean = false, width: number = 0, height: number = 0) => {
  if (config.public.appBase === 'http://localhost:3000') {
    return src
  }

  const urlSafeBase64 = (str: string) => {
    return Buffer.from(str, 'utf8')
      .toString('base64')
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
  }
  const hexDecode = (hex: string) => Buffer.from(hex, 'hex')
  const sign = (salt: string, target: string, secret: string) => {
    const encode = hmac(sha256, hexDecode(secret))
    encode.update(hexDecode(salt))
    encode.update(target)
    return urlSafeBase64(encode.digest())
  }
  const resizingType = 'fill'
  const gravity = 'sm'
  const enlarge = 1
  const encodedUrl = urlSafeBase64(src)
  const path = joinURL('/', `rs:${resizingType}:${width}:${height}:${enlarge}/g:${gravity}/wm:${watermark ? '0.5:we:20:0:0.2' : '0.0:we:20:0:0.2'}/`, encodedUrl);
  const signature = sign(config.public.cdnSalt, path, config.public.cdnKey)

  return joinURL(config.public.cdnUrl, signature, path)
}
